import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useCartParams, useHandleCheckout } from '../hooks/useShopify';
import Layout from '../components/layout';
import LoadingScreen from '../components/loading-screen';

const Express: React.FC = () => {
  const [handleCheckout] = useHandleCheckout();
  const [handleCartParams] = useCartParams();

  useEffect(() => {
    const { cartItems, cartSummary } = handleCartParams();
    if (cartItems && cartItems.length > 0) {
      handleCheckout(cartItems, cartSummary);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Layout hideHeader hideFooter>
      <LoadingScreen message="Loading..." />
    </Layout>
  );
};

export default Express;
